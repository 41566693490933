<template>
  <div>
    <div class="header-top" v-if="$route.query.courseScheduleId">
      <div class="info-wrap">
        <span class="info-name">课节名称：</span>
        <span class="info-con">{{ courseObj.scheduleName }}</span>
      </div>
      <div class="info-wrap">
        <div class="info-item">
          <span class="info-name">授课老师：</span>
          <span class="info-con">{{ courseObj.teacherName }}</span>
        </div>
        <div class="info-item">
          <span class="info-name">上课时间(班级时区)：</span>
          <span class="info-con">
            {{ $moment(courseObj.startDateTime).format('YYYY-MM-DD HH:mm') }} -
            {{ $moment(courseObj.endDateTime).format('YYYY-MM-DD HH:mm').substring(11, 16) }}
          </span>
        </div>
        <div class="info-item">
          <span class="info-name">所属班级：</span>
          <span class="info-con">{{ courseObj.formClassName }}</span>
        </div>
      </div>
    </div>

    <div class="schedule-wrap">
      <div class="left-wrap">
        <video style="width: 100%; border-radius: 10px" :src="firstUrl" controls="controls"></video>
      </div>
      <div class="right-wrap">
        <div class="title">播放列表</div>
        <div class="video-list">
          <video
            class="video-item"
            :class="item.url === firstUrl ? 'border-color' : ''"
            @click="selVideoUrl(item.url)"
            v-for="(item, index) in courseObj.videoPlaybackData || courseObj"
            :key="index"
            :src="item.url || item"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryPlayCourse, getVideoRecord } from '@/api/schedule';

export default {
  name: 'lessonReplay',
  data() {
    return {
      courseObj: {},
      firstUrl: '',
    };
  },
  methods: {
    selVideoUrl(url) {
      this.firstUrl = url;
    },
    queryPlayCourse() {
      if (this.$route.query.courseScheduleId) {
        const params = {
          courseScheduleId: this.$route.query.courseScheduleId,
        };
        queryPlayCourse(params).then((res) => {
          this.courseObj = res.data.content;
          this.firstUrl = this.courseObj.videoPlaybackData[0].url;
        });
      } else if (this.$route.query.roomId) {
        getVideoRecord(this.$route.query.roomId).then((res) => {
          this.courseObj = res.data;
          this.firstUrl = this.courseObj[0];
        });
      }
    },
  },
  created() {
    this.queryPlayCourse();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.header-top {
  padding: 18px 20px;
  background-color: #fff;

  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}

.schedule-wrap {
  background-color: #fff;
  padding: 18px 20px;
  height: 80vh;
}
.info-wrap {
  margin-bottom: 10px;
  display: flex;
  font-size: 14px;
}
.info-item {
  margin-right: 50px;
}
.info-name {
  color: rgba(31, 45, 61, 0.7);
}
.info-con {
  color: #000;
}
.schedule-wrap {
  display: flex;
}
.right-wrap {
  width: 300px;
  padding: 0 0 30px 20px;
  border-left: 1px solid #d9d9d9;
}
.left-wrap {
  flex: 1;
  margin-right: 20px;
}
.video-item {
  width: 208px;
  margin-bottom: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.border-color {
  border: 1px solid #04cb94;
}

.title {
  font-size: 18px;
  color: #1f2d3d;
  padding-bottom: 16px;
}
</style>
